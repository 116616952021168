import { Account } from 'lib/models/account'
import { Organization } from 'lib/models/organization'
import { makeApiRequest } from 'lib/utils/apiUtils/makeApiRequest'

const url = '/account'

const baseKey = 'account'

export const accountQueryKey = [baseKey]

export const accountQueryKeys = {
    getAccount: accountQueryKey,
    getAccountSeries: [baseKey, 'series'],
}

export const accountService = {
    create: async (body) => makeApiRequest({ url: '/register', method: 'POST', body }),
    getAccount: async () => makeApiRequest<GetAccountData>({ url }),
    update: async (body) => makeApiRequest({ url, method: 'PUT', body }),
    updateTemplates: async (body) =>
        makeApiRequest({ url: `${url}/templates`, method: 'PUT', body }),
    getAllSeries: async () => makeApiRequest({ url: `${url}/series` }),

    superadminGetAll: async () => makeApiRequest({ url: '/superadmin/accounts' }),
}

type GetAccountData = {
    account: Account
    organizations: Organization[]
}
